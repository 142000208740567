
export default class FormAjaxSubmit {

  static upcateCkeditorTextarea(instance){
    CKEDITOR.instances[instance].updateElement();
  }

  static submit(form, success_function, enable_button_function) {
    var csrf_token = jQuery('meta[name=csrf-token]').attr('content');
    var csrf_param = jQuery('meta[name=csrf-param]').attr('content');
    var $form = jQuery(form);
    var fd = new FormData($form.get(0));
    fd.append(csrf_param, csrf_token);
    // 連打対策
    var before_button_disables = [];
    $form.find('input[type=button]').each(function(i, item) {
      var $item = jQuery(item);
      before_button_disables[i] = $item.prop('disabled');
      $item.prop('disabled', true);
    });

    jQuery('#ajax-indicator').show();
    jQuery.ajax({
      type: $form.attr('method'),
      url: $form.attr('action'),
      data: fd,
      processData : false,
      contentType : false,
      success: function(response) {
        success_function(response);
        var enable_button = true;
        if (enable_button_function !== undefined && enable_button_function !== null) {
          enable_button = enable_button_function(response);
        }
        if (enable_button) {
          $form.find('input[type=button]').each(function(i, item) {
            jQuery(item).prop('disabled', before_button_disables[i]);
          });
        }
        jQuery('#ajax-indicator').hide();
      }
    });
  }
  
  /* submitのsuccess_functionに指定する関数
   * 新規登録などのバリデーションを伴うケースで利用する
   *
   * バリデーションエラー時のjsonレスポンス
   * { status: 'error', error_target: 'エラーメッセージを表示する対象のセレクタ', error_messages: 'エラーメッセージを含むHTML' }
   * 保存成功時のjsonレスポンス
   * { status: 'success', success_target: '成功時の内容を表示する対象のセレクタ', success_body: '成功時の内容' }
   * { status: 'success', redirect: 'リダイレクト先のURL' }
   */
  static saveFunction (result) {
    if(result['status'] === 'error') {
      jQuery(result['error_target']).html(result['error_messages']);
      if(result['scroll_to'] !== undefined && result['scroll_to'] !== null) {
        jQuery(result['scroll_to'])[0].scrollIntoView();
      }
      if(result['eval_script'] !== undefined && result['eval_script'] !== null) {
        eval(result['eval_script']);
      }
      return;
    }
    if(result['redirect'] !== undefined && result['redirect'] !== null) {
      location.href = result['redirect'];
      return;
    }
    jQuery(result['success_target']).html(result['success_body']);
  }

  static enableButtonFunction(result) {
    if(result['status'] === 'success') {
      return false;
    }
    return true;
  }

  static submitWithAction(form, action, success_function){
    var $form = jQuery(form);
    var before = $form.attr('action');
    $form.attr('action', action);
    FormAjaxSubmit.submit(form, success_function);
    $form.attr('action', before);
  }
  
  // actionを変えずにサブミットしたいなら普通にサブミットすればよいはず
  // actionを変えてサブミットしたいケース（例：検索画面のCSVダウンロードなど）に用いる
  static notAjaxSubmit(form, action){
    var $form = jQuery(form);
    var before = $form.attr('action');
    $form.attr('action', action);
    var csrf_token = jQuery('meta[name=csrf-token]').attr('content');
    $form.find('[name=authenticity_token]').val(csrf_token);
    $form.submit();
    $form.attr('action', before);
  }
  
}
