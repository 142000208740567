export default class ShowPreviewImage {
  static upload(send_to, file, target) {
    var csrf_token = jQuery('meta[name=csrf-token]').attr('content');
    var csrf_param = jQuery('meta[name=csrf-param]').attr('content');
    var $file = jQuery(file);
    if($file.val() === '') {
      return;
    }

    var fd = new FormData();
    fd.append("file", $file.prop("files")[0]);
    fd.append(csrf_param, csrf_token);

    jQuery.ajax({
      type: 'POST',
      url: send_to,
      data: fd,
      processData : false,
      contentType : false,
      success: function(json) {
        var $target = jQuery(target);
        if (json['body'] !== undefined && json['body'] !== null) {
          $target.css('color', '');
          $target.html(json['body']);
        }
        if (json['error'] !== undefined && json['error'] !== null) {
          $target.css('color', 'Red');
          $target.text(json['error']);
        }
      }
    });
  }
};
